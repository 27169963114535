
import React, { useContext, useMemo, useState } from 'react';

import {
  Grid,
} from '@mui/material';
import { SizeContext } from '../../../App';
import { LoadingDisplay } from '../../../Reusables/LoadingDisplay';
import uploadDefault from "../../../assets/images/homeHero.jpg";
import { HomeHero } from '../../../components/Home/HomeHero';
import EditDrawerAbout from '../../EditDrawer/EditDrawer';
import { ImageEdit } from '../../EditDrawer/ImageEdit';
import { TextEdit } from '../../EditDrawer/TextEdit';
import { useEdit } from '../../Hooks/useEdit';
import { useLoadHomeHeroImage } from '../../Hooks/useLoadHomeHeroImage';
import { useModifyHomeHeroImage } from '../../Hooks/useModifyHomeHeroImage';
import { useSession } from '../../utils/useSession';
export function HomeAdmin(
  {

    handleSave,
    drawerWidth,
    setenablesave
  } 
  ){
    const {
      selectedItem,
     
      selected,
      setSelected,
  } = useEdit();
  
  const {addToSessionStorage, getSessionStorage} = useSession();
  const page = "home"
  
  const [open,setOpen] = useState(false);
  // const [selected,setSelected] = useState(false);
  const [border,setBorder] = useState(selected? '5px solid #FF0000':'');
  const matches = useContext(SizeContext);

  let filename = 'homeHero.jpeg';
  let ui = 'admin';
  let key = 'home';
  let path = "home/image/";

  const [status,error,hero] = useLoadHomeHeroImage({ui: ui,path:path,filename:filename,key:key,getSessionStorage});
  const [mutateStatus,mutateError,mutate] = useModifyHomeHeroImage({collection: key,addToSessionStorage,getSessionStorage});

  const heroImage = useMemo(()=>{return (hero == "")?uploadDefault:hero},[status,hero]);
  const handlePopoverOpen = (event) => {
    setBorder('5px solid #FA8072');
  };

  const handlePopoverClose = (event) => {
    setBorder("");
  };

  const handleClick = (e) =>{
      e.preventDefault();
      const id = e.currentTarget.id;
      // setSelected({index:'',component:id,page:page});
      setSelected({id:id,index:0,component:"home",page:page});
      setOpen(true);
  
  }
    //Handle File Upload Preview (home hero handles its image upload differently than the other pages and heros)
  const handlePreview = (data) =>{
      //replace the name with a specific filename so I can always find this file
      //because home hero does not have an object in firestore that stores the url to its image. 
      //I access home hero directly from storage
      //TODO: maybe change this so all components act the same way. it will be easier to remember. 
      data.name = filename;
      console.debug('Handling File Upload. ',data)
      //get the current changes from other pages
      mutate({selected:selected,action:"modify",imageData:data});

      //Enable saving button    
      setenablesave(true);
  }

  if([status,mutateStatus].includes('pending') ){
  
    return(
        <LoadingDisplay />
    ); 
  }
  else{
    return(
      <Grid>
        <Grid
          sx={{
              // border:'2px solid purple',
              pointerEvents:'auto',
              border: `${border}`,
              // width: [status,mutateStatus].includes('error')? '50vw':"",
              // height: [status,mutateStatus].includes('error')?'60vh':"",
              // backgroundColor:[status,mutateStatus].includes('error')?'Grey':"",
          }}
          id='Hero'
          key='homeHero' 
          onMouseOver={(!selected)?handlePopoverOpen:null}
          onMouseOut={(!selected)?handlePopoverClose:null}
          onClick={(e)=>handleClick(e)}      
        >
          <HomeHero matches={matches} heroImage={heroImage} isLoading={false}/>
        </Grid>
        
        <EditDrawerAbout 
          isOpen={open}
          title={"Hero"} 
        >
          <ImageEdit 
            img={heroImage} 
            handlePreview={handlePreview}
            selected={selected}
            drawerWidth={drawerWidth}
          />
          <TextEdit 
            newData={{}}  
            handleChange={{}}
            handleSave={handleSave}
          />
        </EditDrawerAbout>
        :<></>
      </Grid>
    )
  }
   
   
}

    