//This hook will contain all of the functions needed to interact with a 
//section of content aka text object in the CMS admin portal. This will
//be reusable for all the pages. 
//Actions include, creating a new section, editing an existing section, deleting
//This is what happens before the save and will call the modify hooks to store in session object.


/* 
This Hook Returns 
    [section,func,status]
    function: What you want to do to the section on the UI end. This happens in response to user clicks 
        ex. add, change, delete 
    
*/

import { getFirestore, } from "firebase/firestore"; //using this for accessing firestore directly from client. bypassing server
import { getStorage, ref } from "firebase/storage";
import _ from 'lodash';
import { v6 as uuidv6 } from 'uuid';
import { app } from "../../utils/fb";
import { useSession } from '../utils/useSession';
import { useDeleteData } from './useDeleteData';
import { useDeleteFile } from './useDeleteFile';
import { useEdit } from "./useEdit";
import { useModifyData } from './useModifyData';
import { useModifyFileData } from './useModifyFileData';

const db = getFirestore(app());
const storage = getStorage();
export function useSection({collection,setOpenDelete,setdeleteResp}){
    
    const {addToSessionStorage,getSessionStorage} = useSession();
    
    const {selected, setSelected,selectedItem,setSelectedItem} = useEdit();
    
    const [status,error,mutate,mutateAsync] = useModifyData({collection:collection});
    const [mutateFileStatus,mutateFileError,mutateFile,mutateFileAsync] = useModifyFileData({collection:collection});
    const [deleteStatus,deleteError,deleteMutate] = useDeleteData({collection:collection,
      props: {
        setSelected,
        setOpenDelete,
        setdeleteResp
      }
      });
    const [deleteFileStatus,deleteFileError,deleteFileMutate,deleteFileMutateAsync] = useDeleteFile({collection:collection});

    //Add a new Section
    const addSection = ({e,list,dataObject,page}) =>{
        // e.preventDefault();
        let newIndex = list.length;     
        //Create New Data
        if(!dataObject.id){
            dataObject.id = uuidv6();
        }
       
        dataObject.index = newIndex;

        const id = e.currentTarget.id;
        //TODO: I dont need the whole selected here, just pass teh component
        let newSelected = {id:dataObject.id,index:newIndex,component:id,page:page};
        
        //Use the Hook to Store text object in session
        mutate({component:id,action:"create",data:dataObject,addToSessionStorage,getSessionStorage});
        setSelected(newSelected);
        setSelectedItem(dataObject);
        return dataObject;
    }

    //Modify a section
    const modifyText = ({event,list}) => {
        if(event.target){
            let section = event.target.id;
            let value = event.target.value;
          

            let data = list.find((el,ind)=> el.id==selected.id);
            
            Object.assign(data,{[section]:value});
            // data[section] = value;
            //Use the Hook to Store text object in session
            mutate({component:collection,action:"modify",data:data});
            setSelectedItem(data);
        }
    }
     // Delete a Section
    const deleteSection = ({item,component}) =>{
        //Kick off delete after someone confirms they want to delete the item
        // console.debug('DELETE SECTION: getting ready to delete: ',item);
        //set loading modal
    
        deleteMutate({component:collection,action:'delete',data:item});
        
    }
    // Handle File/Image Upload Preview to an about section
    const addImage = async ({data}) =>{
        //Find the current section being modified.
       
        let section = selectedItem;


        //If the image is a hero 
        if((collection == 'hero') && !section){
            let id = uuidv6();
            section = {id:id,title:"",page:selected.page,image:data.blob};
            selected["id"] = selected.id?selected.id:id;
        }

        //See if there is an image already stored, then delete it before adding a new one
        // change item already in the session storage for the current image
        //If so then turn that action into delete. This keeps the storage clean
        if(section.image != ""){
            var storageReference = ref(storage,section.image);
            let imgName = storageReference.name;
            // let pathName = storageReference.fullPath;
            let prev = JSON.parse(sessionStorage.getItem('changes'));
            // let prev = await getSessionStorage();
            if(prev){
                let current = prev.find((item,index) => 
                (item.location.object_id == selected.id) 
                && 
                (item.type == "image")
                &&
                ((item.data.url == section.image) || (item.data.blob == section.image))
                &&
                (item.action != "delete")
                );
                if(current){
                    deleteFileMutate({selected,action:"delete",type:'image',imageData:{name:"",url:"",blob:section.image},addToSessionStorage,getSessionStorage});
            
                }else{
                    //Delete the image stored in the storage
                    deleteFileMutate({selected,action:"delete",type:'image',imageData:{name:imgName,url:section.image,blob:""},addToSessionStorage,getSessionStorage});
                }
            }else{
                await deleteFileMutateAsync({selected,action:"delete",type:'image',imageData:{name:imgName,url:section.image,blob:""},addToSessionStorage,getSessionStorage});
                
            }

                await mutateFileAsync({selected:selected,type:'image',action:"modify",imageData:data,addToSessionStorage,getSessionStorage})
                //Add image to section
                section.image = data.blob;
                //Update section block
                await mutateAsync({component:selected.component,action:"modify",data:section,addToSessionStorage,getSessionStorage});
 
        }else{

        //Add Image change object to session storage 
        // if(deleteFileStatus == "success"){
            mutateFile({selected:selected,type:'image',action:"modify",imageData:data,addToSessionStorage,getSessionStorage})
           //Add image to section
            section.image = data.blob;
            
            //Update section block
            mutate({component:selected.component,action:"modify",data:section});
        }
        
    }
    const deleteImage = ({selected,data,imageData}) =>{
        //TODO: check that imageData.image is the correct way to get image url
             //create session storage object for deleting image out of storage in backend
            deleteFileMutate({selected,action:"delete",type:'image',imageData,addToSessionStorage,getSessionStorage});
            
            data.image = "";
            //create a session storage object for change in the section block
            mutate({component:selected.component,action:"modify",data,addToSessionStorage,getSessionStorage});
     }
    const addContactImage = async ({data}) =>{
        //Find the current section being modified.
        //Add Image change object to session storage 
            mutateFile({selected:selected,type:'image',action:"modify",imageData:data})
    }
    const deleteContactImage = ({selected,data,imageData}) =>{
        //TODO: check that imageData.image is the correct way to get image url
            //create session storage object for deleting image out of storage in backend
            deleteFileMutate({selected,action:"delete",type:'image',imageData,addToSessionStorage,getSessionStorage});    
    }
    const deleteDocumentFromList = ({documentData}) =>{
        //This Function deletes a document from a list of documents.
        //Ex. An Event Section may have text documents uploaded with information.
        //This function will create a change item in the session storage and update the admin
        //UI to show the one document removed
        let data = selectedItem;
        console.debug('deleteDocument from event list');

        //create session storage object for deleting document out of storage in backend
        deleteFileMutate({selected,type:"document",action:"delete",data,imageData:documentData,addToSessionStorage,getSessionStorage});


            //find the document in the list of documents 
            let exists = data.documents.find(item=>_.isEqual(item,documentData));
          
            //remove document from the list
            data.documents.splice(data.documents.indexOf(exists),1);
            // remove(data.documents,exists);

            //create a session storage object for change in the section block
            mutate({component:selected.component,action:"modify",data,addToSessionStorage,getSessionStorage});
     
    }

    const addDocument = ({selected,data,list}) =>{
        //TODO: add document to an event's list of documents

        //TODO: Create a document change item for session storage 
        //Create a mutateDocument similary to mutateFile where I can create a 
        // also create a usemodifyFileData.js file similary to useModifyFileData.js 
        // these two will do the same as image but will be for files/documents
        // change type=file, change the data.image in the section to be like the list of documents?
        
        let section = list.find((el,index)=>el.id==selected.id);

        section.documents.push({name:data.value.name,content:data.value.blob});
        
        mutateFile({selected:selected,type:"document",action:"modify",imageData:data.value,addToSessionStorage,getSessionStorage})

        mutate({component:selected.component,action:"modify",data:section,addToSessionStorage,getSessionStorage});
    }
    //Generic functions
    const addRecurrence = ({dataObject}) =>{
        mutate({component:collection,action:"create",data:dataObject});
    }
    const modifyCollection = ({section,value,dataObject}) =>{
        mutate({component:collection,action:"modify",data:dataObject});
     }
    const deleteCollection = ({dataObject}) =>{
        mutate({component:collection,action:"delete",data:dataObject});
    }

    const func = (type,action,params) =>{
        console.debug('STEP 1: Params:  ',type)

        if(type=="text"){
            switch(action){
                case "add":
                    //call handle add function
                    addSection(params);
                    // setSection(()=>addSection(params));
                    break;
                case "change": 
                    modifyText(params);
                    break;
                case "delete":
                    deleteSection(params);
                    break;
                default:
                    break;
            }
        }else if(type == "image"){
            switch(action){
                case "add":
                case "change": 
                    addImage(params);
                    break;
                case "delete":
                    deleteImage(params);
                    break;
                default:
                    break;
            }
        }
        else if(type == "document"){
            switch(action){
                case "add":
                case "change": 
                    addDocument(params);
                    break;
                case "delete":
                    deleteDocumentFromList(params);
                    break;
                default:
                    break;
            }
        }
        else if(type == 'contactImage'){
            switch(action){
                case "add":
                case "change": 
                    addContactImage(params);
                    break;
                case "delete":
                    deleteContactImage(params);
                    break;
                default:
                    break;
            }
        }
        //handle generic adding object to db. this will create a change object for adding an object to a collection
        else if(type == 'recurrence'){
            switch(action){
                case "add":
                    addRecurrence(params);
                    break;
                case "change": 
                    modifyCollection(params);
                    break;
                case "delete":
                    deleteCollection(params);
                    break;
                default:
                    break;
            }
        }
        
    }    
    
   
    return [func,status];
}
