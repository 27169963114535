
import '../../index.css';
import { Collapse, Grid, ThemeProvider,useMediaQuery } from "@mui/material";
import { Hero } from '../Hero/Hero';
import { theme } from "../Home/Home.theme";
import {  Outlet, useLocation, } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';
import { useLoadData } from '../Hooks/useLoadData';
import design from '../../assets/entiredesign.png'
import { useQuery } from '@tanstack/react-query';

import { setherotitle } from '../../cms/utils/HeroInfo';

// import { getHero } from '../Hero/SetHero';
let defaultHero = '/defaultHero.jpeg';
function getHeroInfo(page){
   return new Promise ((resolve,reject)=>{
    setherotitle('/'+page).then(page =>{
           resolve(page);
       }).catch(e=>{
            reject({});
       });
   })
  
}
const Layout = () => {
   const location = useLocation();
   const {  pathname } = location;
   // let page =  pathname.substring(7);
   let page = pathname.split('/').slice(1).join("/");
   const [loaded,setloaded] = useState(false);
   const matches = useMediaQuery(theme.breakpoints.down('md'));

   const [status,error,heroList] =  useLoadData({collection:'hero'});
   
   const {status:statusHero,error:errorHero, data:heroInfo} = useQuery({
      queryKey: ["heroInfo"+page],
      queryFn:() => getHeroInfo(page),
    });
      const hero = useMemo(()=>{
            if((status == 'success') && (heroList.length>0)){
               //If there are hero objects stored in the db
               //then look for the one for this page
               //if it exists then return that info
               //if not then check to see if the default hardcoded hero info
                  let exists = heroList.find((item)=>item.page == page);
                  if(exists){
                     setloaded(true);
                     return exists;
                  }else if(statusHero == 'success' && heroInfo){
                     setloaded(true);
                     return {id:"",page:"",title:heroInfo.title,image:heroInfo.image};
                  }else{
                     setloaded(true);
                     return {id:"",page:"",title:"",image:""};
                  }
            }else if(statusHero == 'success'){
               //if there are no heros in the db or it fails to load
               //then check to see the hardcoded default hero info has been gathered
               //return the default hero info
               setloaded(true);
               return {id:"",page:"",title:heroInfo.title,image:defaultHero};
               
            }else if([status,statusHero].includes('pending')){
               return {id:"",page:"",title:"",image:""};
            }
            else{
               setloaded(true);
               return {id:"",page:"",title:"",image:""};
            }
     },[status,statusHero,heroList,pathname]);
  



   return(
      <ThemeProvider theme={theme}>

         <Grid >
            {loaded?
            <Collapse in={loaded} 
                 
                 style={{ transformOrigin: '0 0 0' }}
                 easing={{
                    enter: "cubic-bezier(0, 1.5, .8, 1)",
                    exit: "linear"
                  }}
                 {...(loaded ? { timeout: 1500 } : {})}
                 >

            <Hero key={pathname} hero={hero} loaded={loaded}/>    
            </Collapse>  
            :
            <></>
            }
            <Grid sx={matches?
            {
               backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)),
               url(${design})`,
               backgroundPositionX: 'center',
               backgroundSize: 'cover',
               backgroundRepeat: 'no-repeat',
               padding: '6%',
            }
            :
            { 
               backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)),
               url(${design})`,
               backgroundPositionX: 'center',
               backgroundSize: 'cover',
               backgroundRepeat: 'no-repeat',
               padding: '6%',
               paddingInline:'10%',
               display:'flex',
            }}> 

               <Outlet/>
            </Grid>
            {/* anything else you want to appear on every page that uses this layout */ }
         </Grid>
         </ThemeProvider>
      
   );
};

export default Layout;