import {
    CssBaseline,
    Grid,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";


import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEdit } from "../Hooks/useEdit";
import { useAuth } from "../auth/useAuth";
export function SideDrawer ({pathname}){
    const {
        setSelected,
        } = useEdit();

    const paths = [
      "/admin","/admin/about","/admin/about/who","/admin/about/where",
      "/admin/ministry","/admin/event","/admin/connect","/admin/announcement","/admin/connect/visit",
      "/admin/connect/join","/admin/connect/prayer",
    ];
    
    const {isAdmin} = useAuth();
    const navigate = useNavigate();
    // const [open, setOpen] = useState(true);
    const [selectedIndex,setSelectedIndex] = useState(()=>{
        if(isAdmin){
            return paths.indexOf(pathname);
        }else{
            return ([4,5].includes(paths.indexOf(pathname)))? paths.indexOf(pathname):null;
        }
    });
    
    const handleClick = (e) =>{
        const {
            target: { value,name }} = e;
        
        let index = value;
        setSelectedIndex(index);
        setSelected(false);
        e.preventDefault();
        if(isAdmin){
            switch (index){
                case 0:
                    navigate("/admin");
                    break;
                case 1: 
                    navigate("/admin/about");
                    break;
                case 2: 
                    navigate("/admin/about/who");
                    break;  
                case 3: 
                    navigate("/admin/about/where");
                    break;  
                case 4: 
                    navigate("/admin/ministry");
                    break; 
                case 5: 
                    navigate("/admin/event");
                    break; 
                case 6: 
                    navigate("/admin/connect");
                    break;    
                // case 7: 
                //     navigate("/admin/connect/visit");
                //     // setPage('visit');
                //     break;
                // case 8: 
                //     navigate("/admin/connect/join");
                //     // setPage('join');
                //     break;
                // case 9: 
                //     navigate("/admin/connect/prayer");
                //     // setPage('prayer');
                //     break;        
                case 7:
                    navigate("/admin/announcement");
                    break;
                default:
        
                }
        }else{
            switch (index){
                case 4: 
                    navigate("/admin/ministry");
                    break; 
                case 5: 
                    navigate("/admin/event");
                    break; 
                default:
                    break;
        
                }
        }
    }

    return(
        <Grid >
            <CssBaseline />
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectedIndex}
                onChange={(e)=>handleClick(e)}
                input={<OutlinedInput label="Name" />}
                // MenuProps={MenuProps}
                sx={{width:'10vw'}}
                >
                {['Home', 'About', 'Who We Are','Where We\'ve Been', 'Ministries', 'Events','Connect','Announcement'
                ].map((name,index) => (
                    <MenuItem
                    key={name}
                    value={index}
                    name={name}
                    disabled={!isAdmin&&(![4,5].includes(index))}
                    >
                    {name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    )
}