import { Button, Container, Grid,  ThemeProvider, Typography, CircularProgress, Avatar, Box, Paper, useMediaQuery} from '@mui/material';
import {theme} from './SectionContent.theme';
import {theme as hometheme} from '../Home/Home.theme';
import React, { useEffect, useState, useContext } from 'react';
import {getFBToken} from '../../utils/auth';
import {getAll, getFile, getOne} from '../../api/api';
import { blue,red,orange } from '@mui/material/colors';
import { ColorBlockedImage } from '../../Reusables/ColorBlockedImage';
import { SizeContext } from '../../App';

export function SectionContent({section}) {

    const matches = useMediaQuery(hometheme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={hometheme}>
            <Grid 
                variant="SectionContentMain"
                style={matches?
                    {
                    flexDirection:'column',
                    }:{}}
                > 

                {!matches && section.image &&  
                <Grid
                    variant='SectionContentImageGrid'
                    style={matches?
                        {
                        marginRight:20,
                        // width: 'auto',
                    }:{}}
                >
                    <ColorBlockedImage 
                            style={{width:'90vw'}}
                        srcImage={section.image} />
                </Grid>}
                        
                <Grid 
                            variant="SectionContentTextGrid"
                            style={matches?
                                {
                                alignItems:'center'
                            }:{}}
                        >

                            {section.title && 
                            <Paper elevation={8} square sx={{
                                width:'fit-content',
                                backgroundOrigin:'content-box',
                                backgroundPosition:'110% 75%',
                                p:1,
                                // border:'2px solid green',
                                // backgroundColor:hometheme.palette.cream.main,
                                boxShadow:`-10px 0px 0px 6px ${hometheme.palette.grey.main}`
                                }}> 
                                <Typography style={{
                                color:hometheme.palette.primary.main,
                                
                            }}
                            variant="h3">{section.title}</Typography>
                            </Paper>
                            }
                            {section.subtitle && <Typography variant="h4">{section.subtitle}</Typography> }                               
                            <br/>
                            {matches && section.image &&  
                            <Grid
                                variant='SectionContentImageGrid'
                                style={matches?
                                    {
                                    marginRight:20,
                                    // width: 'auto',
                                }:{}}
                            >
                                <ColorBlockedImage 
                                    style={{width:'90vw'}}
                                    srcImage={section.image} />
                            </Grid>}

                            <Grid 
                                variant="SectionContentTextGrid"
                                style={matches?
                                    {
                                    alignItems:'center'
                                }:{}}>
                                {section.body && <Typography variant="body1">{section.body}</Typography>}
                                {section.description && <Typography variant="body1">{section.description}</Typography>}
                                
                                {(section.contact?.length>0) 
                                    &&
                                    <Grid variant="ministryContactGrid">
                                        {section.contact.map((contact,index)=>{
                                            return(
                                                <Grid
                                                    key={`contact_${index}`}
                                                    variant="ministryContactGrid"
                                                >
                                                    {contact.image &&
                                                        <div>
                                                            <Avatar src={contact.image}></Avatar>
                                                            <Grid style={{ display:'flex',width:'1rem'}}></Grid>
                                                        </div>
                                                    }
                                                    
                                                    <Grid 
                                                        variant="ministrySectionContent">
                                                        {contact.name 
                                                        &&
                                                        <Typography variant="h6" noWrap sx={{fontWeight:'bold'}}>{contact.name}</Typography> 
                                                        }   
                                                        {contact.email &&
                                                        <Typography variant="body1" noWrap>{contact.email}</Typography> 
                                                        }
                                                        
                                                        {contact.phone &&
                                                        <Typography variant="body1" noWrap>{contact.phone}</Typography> 
                                                        }
                                                        {contact.address &&
                                                        <Typography variant="body1" noWrap>{contact.address}</Typography> 
                                                        } 
                                                    </Grid>
                                                    <Grid style={{ display:'flex',width:'2.5rem'}}></Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>}
                            </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}