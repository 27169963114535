import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Modal,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useEffect, useMemo, useState } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Timestamp } from 'firebase/firestore';
// import { theme } from "../../cms/CMS.theme";

import { Add, Clear } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useLocation } from "react-router-dom";

let theme = createTheme(
    {
        components:{
          FormControl:{
            styleOverrides :{
                root:({ownerState}) => ({
                    marginTop:'1rem'
                })
            }
            },
            MuiInputBase:{
                styleOverrides :{
                    root:({ownerState}) => ({
                        
                        '&.MuiOutlinedInput-root':{
                            
                        }
                    }),
                },
               
            },
        }
    }
    )

function RecurrenceModal({open,newData,handleClose,handleSubmit,recurrence}){
    //Recurrence 
    const [interval, setInterval] = useState(1);
    const [repeat, setRepeat] = useState('none');
    const [ends,setEnds] = useState('none');
    const [byDate,setByDate] = useState();
    const [byWeekDay,setbyWeekDay] = useState(() => []);
    const [count,setCount] = useState();
    const [until,setUntil] = useState();
    const [byMonthDay,setbyMonthDay] = useState();
    //dayjs(newData.date).date()
    useEffect(()=>{
        setNewRecur(recurrence);
    },[recurrence,newData])
    
    let eventId = newData.id;
    const handleByWeekDay = (event,newbyweekday) => setbyWeekDay(newbyweekday);
    const [newRecur, setNewRecur] = useState(recurrence);

    const processSubmit = (e) =>{
        
        handleSubmit(e,newRecur);
    }

    const handleChange = (e)=>{
        //This will set the state of the new recurr/recurr changes
        let name = e.target.name;
        let value = e.target.value;
        let data = newRecur;
        if(Array.isArray(data[name])){
            data[name].push(value);

            value = data[name];
        }
        setNewRecur((prev)=>{return {...prev, ...{[name]:value}}});
    }

    const minDate = useMemo(()=>(newData.date?._seconds)? 
                    dayjs.utc(new Timestamp(newData.date._seconds,newData.date._nanoseconds).toDate())
                    :
                    dayjs.utc(newData.date),[newData.date]);

    useEffect(()=>{
        if((newRecur?.until && newRecur?.until != "" )){
            if((newRecur?.until.seconds || newRecur?.until.nanoseconds)){
                setUntil(new Timestamp(newRecur?.until.seconds,newRecur?.until.nanoseconds));
            }else if((newRecur?.until._seconds || newRecur?.until._nanoseconds)){
                setUntil(new Timestamp(newRecur?.until._seconds,newRecur?.until._nanoseconds));
            }else{
                setUntil(Timestamp.fromDate(new Date(newRecur?.until)));
            }
        }
        
    },[newRecur?.until]);     
    
    

    useEffect(()=>{
       //calculate byDate and byMonthDay
       if(newRecur?.repeat=="month"){
            setByDate(minDate.date());
            setbyMonthDay(minDate.day());
       }
      
    },[newRecur?.repeat]);

    const [selected,setSelected] = useState(false);
   
    useEffect(()=>{
        if(newRecur?.byDate==""){
            setSelected(false);
        }else{
            setSelected(true);
        }
    },[newRecur?.byDate]);

    return(
        <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
        >
            <Grid 
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <FormControl>
                    <FormLabel sx={{
                        alignSelf:'center',
                        fontSize:'1.5rem',
                        color:'black'
                    }}>Set Recurrence</FormLabel>
                    <form onSubmit={(e)=>processSubmit(e,newRecur)}>                    
                        <Grid style={{display:'flex',flexDirection:'row'}} > 
                            <FormControl style={{display:'flex',marginTop:'1rem'}} >
                                <FormLabel style={{marginLeft:'1rem'}} htmlFor="component-outlined">Repeat Every</FormLabel>
                                    <OutlinedInput
                                        id="interval"
                                        name="interval"
                                        key={`interval_${newRecur?.id}`}
                                        disabled={newRecur?.repeat == 'none'}
                                        type="number"
                                        min={1}
                                        value={newRecur?.interval}
                                        label="Repeat Every"
                                        onChange={(e)=>handleChange(e)}
                                        sx={{
                                            '&.MuiOutlinedInput-root':{
                                                width:'5rem'
                                            }
                                        }}
                                        />
                            </FormControl>
                            <FormControl style={{display:'flex',marginTop:'1rem',alignSelf:'end'}} >
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="repeat"
                                        name="repeat"
                                        key={`repeat_${newRecur?.id}`}
                                        value={newRecur?.repeat}
                                        onChange={(e)=>handleChange(e)}
                                        input={<OutlinedInput sx={{
                                            '&.MuiOutlinedInput-root':{
                                                width:'6rem'
                                            }
                                        }} />}
                                        >
                                        {['none','day', 'week', 'month','year']
                                        .map((name,index) => (
                                            <MenuItem
                                            key={name}
                                            id={'repeat'}
                                            value={name}
                                            name={name}
                                            >
                                            {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                            </FormControl>
                        </Grid>
                        {(newRecur?.repeat=='month') &&
                        <Typography>{`Monthly on day ${byDate}`}</Typography>
                       
                        }
                        <FormControl sx={{maxWidth:'100%',marginLeft:'1rem',marginTop:'1rem'}} style={{display:'flex'}} >
                            <FormLabel htmlFor="demo-radio-buttons-group-label">Ends</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={newRecur?.ends}
                                name="ends" 
                                onChange={handleChange}
                            >
                                <FormControlLabel disabled={newRecur?.repeat=='none'} value="none" control={<Radio />} label="Never" />
                                <Grid style={{display:'flex',flexDirection:'row',width:'5rem%'}} >
                                    <FormControlLabel disabled={newRecur?.repeat=='none'} value="until" control={<Radio />} label="On" />
                                    <DatePicker
                                        key={`until_${newRecur?.id}`}
                                        name="until"
                                        disabled={newRecur?.ends != 'until'}
                                        slotProps={{
                                            sx:{
                                                '&.MuiOutlinedInput-root':{
                                                    width:'2rem',
                                                    color:'red'
                                                },
                                            }
                                        }}
                                        minDate={minDate}
                                        value={dayjs.utc(until?.toDate())}
                                        onChange={(newValue) => {
                                                setUntil(Timestamp.fromDate(newValue.toDate()));
                                        }}
                                        onAccept={(newValue)=>{ handleChange({target:{value:Timestamp.fromDate(newValue.toDate()),name:'until'}})}}
                                    />
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" >Ok</Button>
                    </form>

                </FormControl>
            </Grid>
        </Modal>
    );
} 

export function TextEdit (
    {
        newData,
        handleChange,
        handleSave,
        handleClickAddLink,
        handleClickAddContact,
        handleClickRemoveLink,
        handleClickRemoveContact,
        handleOpenRecurrence,
        recurrence,
        handleSubmitRecurr,
        open,
        setOpen,
        children
        })
    {
        dayjs.extend(utc);
        let {pathname} = useLocation();
        let page = pathname.substring(7);

        const [newlink,setnewlink] = useState("");
        const [name,setname] = useState("");
        const [title,settitle] = useState("");
        const [email,setemail] = useState("");
        const [phone,setphone] = useState("");
        const [address,setaddress] = useState("");
        const [image,setimage] = useState("");
        let file = children?.props?children.props.file:"";

        useEffect(()=>{

            setimage(file);
        },[file]);
        
        //Todo: make contact editable
        // const editContact = (e,contact,index) =>{
        //     
        //     console.debug('editing contact: ',e.target);
        // }
      
        //DATE PICKER
        const [selectedDate, setSelectedDate] = useState();
        const [recuUntil,setRecuUntil] = useState();
        
        useEffect(()=>{
            if((newData?.date && newData?.date != "" )){
                if( newData?.date.seconds || newData?.date.nanoseconds){
                    setSelectedDate(new Timestamp(newData?.date.seconds,newData?.date.nanoseconds));
                }else if((newData?.date._seconds || newData?.date._nanoseconds)){
                    setSelectedDate(new Timestamp(newData.date._seconds,newData.date._nanoseconds));
                }else if(dayjs.isDayjs(newData?.date)){
                    setSelectedDate(Timestamp.fromDate(newData?.date.toDate()));
                }else{
                    setSelectedDate(Timestamp.fromDate(new Date(newData?.date)))
                }
            }
        },[newData,newData?.date]);
           
        useEffect(()=>{
            if((recurrence?.until && recurrence?.until != "" )){
                if((recurrence?.until?.seconds)){
                    setRecuUntil(new Timestamp(recurrence?.until.seconds,recurrence?.until.nanoseconds));
                }else if((recurrence?.until._seconds || recurrence?.until._nanoseconds)){
                    setRecuUntil(new Timestamp(recurrence?.until._seconds,recurrence?.until._nanoseconds));
                }else if(dayjs.isDayjs(recurrence?.until)){
                    setRecuUntil(Timestamp.fromDate(recurrence?.until.toDate()));
                }else{
                    setRecuUntil(Timestamp.fromDate(new Date(recurrence?.until)))
                }
            }
            
        },[recurrence,recurrence?.until]);

        //Close Recurrence Modal
        const handleClose = () => {
            setOpen(false);
        }
   
        recurrence = Array.isArray(recurrence)?recurrence[0]:recurrence;
        const buttonText = useMemo(()=>{
            if(recurrence){
                if(Object.keys(recurrence).length>0){
                    if( (recurrence?.repeat == 'none')){
                        return `Does not repeat`
                    }else {
                        let text = `Repeats Every ${recurrence?.interval} ${recurrence?.repeat}`
                        if(recurrence?.interval>1){
                            text += `s`;
                        } 
                        if((recurrence?.ends=='until') ){
                            text += ` and Ends On ${dayjs.utc(recuUntil?.toDate()).format('MM/DD/YYYY')}`;
                        }
                        else if((recurrence?.ends=='none')) {
                        text += ` and Never Ends`;
                        }
                        return text;
                    }
                }else{
                    return "";
                }
            }else{
                return "";
            }
        },[recurrence]);

        return(
            <ThemeProvider theme={theme}>
            <Grid>
                {open && recurrence &&<RecurrenceModal 
                open={open} 
                handleClose={handleClose} 
                newData={newData} 
                handleSubmit={handleSubmitRecurr}
                // handleChange={handleRecurrChange}
                recurrence={recurrence}
                />}
                <Box
                    id="textEditForm"
                    component="form"
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        fontFamily:"League Spartan",
                        ' & .MuiInputBase-root': {  
                        m: 1, 
                        width: '23ch' },
                    }}
                    autoComplete="off"
                    onSubmit={handleSave}
                >
                    <div>
                    {((newData?.title!= null) && (newData?.title != undefined))?
                        <FormControl required={page.toLocaleLowerCase()!='event'}
                        >
                            <InputLabel htmlFor="component-outlined">{(page=='about/who')?"Name":"Title"}</InputLabel>
                            <OutlinedInput
                            id="title"
                            name="title"
                            key={newData?.index}
                            value={newData?.title}
                            // value={newData?.title}
                            label={(page=='about/who')?"Name":"Title"}
                            onChange={(e)=>handleChange(e)}
                            />
                        </FormControl>
                    :<></>}
                    {((newData?.subtitle != null) && (newData?.subtitle != undefined))?
                        <FormControl>
                            <InputLabel htmlFor="component-outlined">{(page=='about/who')?"Leadership Role":"Subtitle"}</InputLabel>
                            <OutlinedInput
                            id="subtitle"
                            name="subtitle"
                            key={newData?.index}
                            value={newData?.subtitle}
                            label={(page=='about/who')?"Leadership Role":"Subtitle"}
                            onChange={(e)=>handleChange(e)}
                            />
                        </FormControl>
                    :<></>}
                        {((newData?.description != null) && (newData?.description != undefined))?
                        <FormControl required={page.toLocaleLowerCase()!='event'}>
                            <InputLabel htmlFor="component-outlined">Description</InputLabel>
                            <OutlinedInput
                            id="description"
                            name="description"
                            key={newData?.index}
                            value={newData?.description}
                            label="Description"
                            onChange={(e)=>handleChange(e)}
                            />
                        </FormControl>
                    :<></>}
                    {((newData?.body!= null) && (newData?.body != undefined))?
                        <FormControl required >
                            <InputLabel htmlFor="component-outlined">Body</InputLabel>
                            <OutlinedInput
                            
                            id="body"
                            name="body"
                            key={newData?.index}
                            value={newData?.body}
                            label="Body"
                            onChange={(e)=>handleChange(e)}
                            multiline
                            rows={5}
                            />
                        </FormControl>
                    :<></>}
                     {((newData?.location!= null) && (newData?.location != undefined)) &&
                        <FormControl required={page.toLocaleLowerCase()!='event'}>
                            <InputLabel htmlFor="component-outlined">Location</InputLabel>
                            <OutlinedInput
                            id="location"
                            name="location"
                            key={newData?.index}
                            value={newData?.location}
                            label="Location"
                            onChange={(e)=>handleChange(e)}
                            />
                        </FormControl>
                    }
                    {((newData?.date!= null) && (newData?.date != undefined)) &&
                        <FormControl required style={{marginBottom:'1rem'}}  >
                            <FormLabel style={{fontSize:'1.3rem',marginBottom:'1rem'}} >Event Date</FormLabel>
                                <DateTimePicker 
                                    id="date"
                                    key={newData?.index}
                                    name="date"
                                    label="Date"
                                    minDate={new dayjs.utc()}
                                    disablePast
                                    slotProps={{
                                        day: {
                                            sx: {
                                            fontFamily:'League Spartan'
                                            }
                                        },
                                        calendarHeader:{
                                            sx: {
                                                '& .MuiPickersCalendarHeader-label':{
                                                    fontFamily:'League Spartan',
                                                },
                                            }
                                        },
                                    }}
                                    value={dayjs.utc(selectedDate?.toDate())} 
                                    // onChange={(value)=>setSelectedDate(value)}
                                    onAccept={(value)=>{setSelectedDate(value);handleChange(value)}}
                                >
                                </DateTimePicker>
                                {recurrence && (Object.keys(recurrence).length>0)?
                                <Button variant="outlined" disabled={newData.date == ""} onClick={() => setOpen(true)}>
                                    {buttonText}
                                </Button>
                                :
                                <Button disabled={newData.date == ""} onClick={(e)=>{
                                    // setOpen(true);
                                    handleOpenRecurrence(newData?.recurrence);
                                    // handleClickAddRecurrence(e);
                                }}>Repeats...</Button>
                                }
                        </FormControl>
                    }
                   
                    {((newData?.contact != null && newData?.contact != undefined )) &&
                        <FormControl
                        >
                            <Typography style={{marginLeft:'10px'}}>Contact Information</Typography>
                            <List >
                                {newData?.contact?.map((contact,index)=>{
                                    return (
                                        <Grid>
                                            <ListItem
                                            sx={{paddingInline:0}}
                                            key={contact}
                                            secondaryAction={
                                                <ButtonGroup>
                                                    {/* <IconButton onClick={(e)=>editContact(e,contact,index)} >
                                                        <Edit />
                                                    </IconButton> */}
                                                    <IconButton edge="end" onClick={(e)=>handleClickRemoveContact(e,contact)}>
                                                        <Clear />
                                                    </IconButton>
                                                </ButtonGroup>
                                            }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp" src={contact.image} />
                                                </ListItemAvatar>
                                                <ListItemText    
                                                    primaryTypographyProps={{
                                                    fontSize: 14,
                                                
                                                }}
                                                >{contact.name} {contact.title} {contact.email} {contact.phone} {contact.address} </ListItemText>
                                                {/* <ListItemIcon style={{width:'fit-content'}} onClick={(e)=>editContact(e,contact,index)} >
                                                    <Edit />
                                                </ListItemIcon> */}
                                                {/* <ListItemIcon onClick={(e)=>handleClickRemoveContact(e,contact)} >
                                                    <Clear />
                                                </ListItemIcon> */}
                                            
                                            </ListItem>
                                            <Divider/>
                                        </Grid>
                                    )
                                })}
                            </List>
                            <Grid>
                                {children}
                                
                                <FormControl >
                                    <InputLabel htmlFor="component-outlined">Name</InputLabel>
                                    <OutlinedInput
                                    id="contact.name"
                                    name="contact.name"
                                    key={newData?.index}
                                    value={name}
                                    label="Contact Name"
                                    onChange={(e)=>setname(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl >
                                    <InputLabel htmlFor="component-outlined">Title</InputLabel>
                                    <OutlinedInput
                                    id="contact.title"
                                    name="contact.title"
                                    key={newData?.index}
                                    value={title}
                                    label="Contact Title"
                                    onChange={(e)=>settitle(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl >
                                    <InputLabel htmlFor="component-outlined">Email</InputLabel>
                                    <OutlinedInput
                                    id="contact.email"
                                    name="contact.email"
                                    key={newData?.index}
                                    value={email}
                                    label="Contact Email"
                                    onChange={(e)=>setemail(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <InputLabel htmlFor="component-outlined">Phone</InputLabel>
                                    <OutlinedInput
                                    id="contact.phone"
                                    name="contact.phone"
                                    key={newData?.index}
                                    value={phone}
                                    label="Contact Phone"
                                    onChange={(e)=>setphone(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <InputLabel htmlFor="component-outlined">Address</InputLabel>
                                    <OutlinedInput
                                    id="contact.address"
                                    name="contact.address"
                                    key={newData?.index}
                                    value={address}
                                    label="Contact Address"
                                    onChange={(e)=>setaddress(e.target.value)}
                                    />
                                </FormControl>
                                
                                <Button 
                                    variant="contained" 
                                    id="contact"
                                    onClick={(e)=>{
                                        setname("");
                                        settitle("");
                                        setemail("");
                                        setphone("");
                                        setaddress("");
                                        setimage("");
                                        handleClickAddContact(e,{
                                            name:name,
                                            title:title,
                                            email:email,
                                            phone:phone,
                                            address:address,
                                            image:image
                                        })
                                    }}
                                    endIcon={<Add />}>
                                    Add Contact
                                </Button>
                            </Grid>
                           
                        </FormControl>
                    }
                    {((newData?.links!= null) && (newData?.links != undefined)) &&
                        <FormControl >
                            <FormLabel style={{fontSize:'1.3rem'}} htmlFor="component-outlined">Links</FormLabel>
                            <List>
                                {newData.links.map((link,index)=>{
                                return (
                                    <ListItem
                                    >
                                        <ListItemText    
                                            primaryTypographyProps={{
                                            fontSize: 14,
                                            }}>
                                            {link}
                                        </ListItemText>
                                        <ListItemIcon onClick={(e)=>handleClickRemoveLink(e,link)} >
                                            <Clear />
                                        </ListItemIcon>
                                    </ListItem>
                                    )
                                })}
                            </List>
                            <Grid>
                                <OutlinedInput
                                    id="links"
                                    name="links"
                                    placeholder="comma separated list, eg. Link1,Link2"
                                    key={newData?.index}
                                    value={newlink}
                                    label="Links"
                                    onChange={(e)=>setnewlink(e.target.value)}
                                    endAdornment={<InputAdornment position="end">
                                            <IconButton 
                                                id="links"
                                                onClick={(e)=>{setnewlink(""); handleClickAddLink(e,newlink)}}
                                                >
                                                <Add />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                />
                            </Grid>
                        </FormControl>
                    }
                    </div>
                </Box>
            </Grid>
         </ThemeProvider>
        )
    }