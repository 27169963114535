import { createTheme, responsiveFontSizes,styled } from '@mui/material/styles';
import { blue,red,orange } from '@mui/material/colors';
import {theme as hometheme } from '../../Home/Home.theme';

//Todo: fix this
 export const BoxDiv = styled("div", {
    name: "MuiDiv",
    overridesResolver: (props, styles) => {
      return [styles.root];
    }
  })();

export let theme = createTheme({
    components: {
        MuiDiv: {
            styleOverrides: {
              root: {
                
                boxShadow:'0px 2px 2px 0px rgba(0,0,0,0.23)',
                padding:'2rem',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                backgroundColor: 'white',
              }
            }
          }
        ,
        MuiGrid:{
            variants: [
                {
                    props: {variant:'top'},
                    style:{
                        display:'flex',
                        flexDirection:"row",
                 
                        justifyContent:'center',
                        width:'100%',
                        marginBottom:'5vh',
                        alignItems:'center'
                    }
                },
                {
                    props: {variant:'smalltop'},
                    style:{
                        display:'flex',
                        flexDirection:"column",
                    
                        justifyContent:'center',
                        width:'100%',
                        marginBottom:'5vh',
                        alignItems:'center'
                    }
                },
                {
                    props: {variant:'main'},
                    style:{
                        // backgroundImage:`url(${design})`,
                    
                        // border:`5px dashed ${hometheme.palette.primary.main}`, 
                        // height:"50vh",
                        display:"flex",
                        flex:1,
                        // width:'80%',
                        flexDirection:"column",
                        alignItems:"center",
                        padding:"1.0rem"
                        
                    }
                },
                {
                    props: {variant:'post'},
                    style:{ 
                        display:'flex',
                        flexDirection:'column',
                        flex:1,
                        width:'100%',
                        // sx:{elevation: 3},
                        
                     }
                },
                {
                    props: {variant:'postA'},
                    style:{ 
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        
                        // border:`5px dashed blue`, 
                        justifyContent:'space-between'
                     }
                },
                {
                    props: {variant:'postB'},
                    style:{ 
                        display:'flex',
                        flexDirection:'row',
                        //flex:1,
                        // minWidth:'max-content',
                        alignItems:'center',
                        // // border:`5px dashed red`, 
                        // alignSelf:'flex-end'

                     }
                },
                {
                    props: {variant:'formContainer'},
                    style:{
                        display:'flex',

                        //  border: '6px dashed red',
                    }
                },
                {
                    props:{variant:'modalBox'},
                    style:{
                        display:'block',
                        boxShadow:8,
                        padding:'2rem',
                                        position: 'absolute',
                                        // display:'flex',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        backgroundColor: 'white',
                    }
                },
                {
                    props: {variant:'sharecontainer'},
                    style:{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-evenly',
                        alignItems:'baseline',
                        // border:'2px dashed red'
                    }
                }
                
            ]
        },
        MuiBox:{
            styleOverrides:{
                root:{
                    boxShadow:8,padding:'2rem',
                                        position: 'absolute',
                                        // display:'flex',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        backgroundColor: 'white',
                }
            },
            variants:[
                {
                    props:{variant:"modalBox"},
                    style:{
                        boxShadow:2,
                        padding:'2rem',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                    }
                }
            ]
        },
        MuiPaper:{
            styleOverrides:{
                root: {
                    display:'flex',
                    flexDirection:'column',
                     // flex:1,
                    maxWidth:'100%',
                    // width:'100%',
                    height:'fit-content',
                    marginBottom:'4vh',
                    // p:2,
                   
                    padding:'1.5rem',
         
              
                }

            }
            ,
            variants:[
                    {
                        props: {variant:'prayerContainer'},
                        style:{
                            display:'flex',
                            flexDirection:'column',
                             // flex:1,
                            maxWidth:'100%',
                            
                            height:'fit-content',
                            marginBottom:'4vh',
                            // p:2,
                           
                            padding:'1.5rem',
                            
                            // position: 'absolute',
                            // top: '50%',
                            // left: '50%',
                            // transform: 'translate(-50%, -50%)',
                            // width: 400,
                            // backgroundColor: hometheme.palette.secondary,
                            // border: '2px solid #000',
                            // boxShadow: 24,
                            // p: 4,
                        }
                    }
                
            ]
            
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'League Spartan',
                    marginBottom: '0.5rem',
                    color: hometheme.palette.secondary.main,
                    
                }
            },
            variants: [
                {
                    props: {variant:'prayer'},
                    style: {
                        fontSize: '1.2rem',
                        fontWeight: 'normal',
                        textTransform: 'uppercase',



                    }
                },
                {
                    props: {variant:'date'},
                    style: {
                        fontSize: '1rem',
                        fontWeight: 'light',
                        fontStyle: 'italic',
                        

                    }
                },

                {
                    props: {variant:'name'},
                    style: {
                        fontSize: '2.2rem',
                        fontFamily: 'Puzzled',
                        

                    }
                }

            ]
        },
        MuiTextField:{
            
           
            
            styleOverrides:{
               
                root:{  
                    '--TextField-brandBorderColor': hometheme.palette.info,
                    '--TextField-brandBorderHoverColor': hometheme.palette.info,
                    '--TextField-brandBorderFocusedColor':  hometheme.palette.info,
                    '& label.Mui-focused': {
                       
                      color: hometheme.palette.info,
                    },

                    // "& .MuiInputBase-input": {
                    //     color:hometheme.palette.secondary
                    // },
                    // "& .Mui-focused":{
                    //     borderColor:hometheme.palette.info
                    // },
                    // "& .MuiInputBase-root":{
                    //     border:hometheme.palette.info
                    // },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: hometheme.palette.info,
                        },
                    },
                    '&:hover fieldset': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'yellow',
                      },
                },
                outlined:{
                    width:'70vw'
                }
            },
            variants:[
                {
                    props: {variant:'outlined'},
                    style:{
                        width:'50%'
                    }
                },
                {
                    props: {color:'highlight'},
                    style:{
                       color:hometheme.palette.info,
                    }
                }
            ],
        },  
        MuiButton:{
            styleOverrides:{
                root:{
                    marginRight:'1vw',
                    color: 'white',
                    backgroundColor: hometheme.palette.primary.main,
                    fontFamily:'League Spartan',
                    fontSize: '1rem',
                    paddingLeft:'1rem',
                    paddingRight: '1rem',
                    height:'fit-content',
                    // fontWeight: 'bold'
                    '&:hover': {
                        backgroundColor: 'white',
                        color: hometheme.palette.primary.main,
                        border:`1px solid ${hometheme.palette.primary.main}`,
                       
                    },
                    '&:disabled':{
                        backgroundColor: 'grey',
                        color: 'black'
                    }
                },
            }
        },
        MuiBackdrop:{
            styleOverrides:{
                root:{
                    // backgroundColor:  'rgba(0, 0, 0, 0.2)',
                   
                }
            }
        },
        MuiCircularProgress:{
            styleOverrides :{
                root:({ownerState}) => ({
                  color: hometheme.palette.primary.main, 
                })
              },
        },
        MuiFormControlLabel:{
            variants:[
                { props:{variant:'email'},
                style:{
                    display:'flex',
                    alignItems:'end',
                    fontFamily:'League Spartan',
                   
                    textTransform: 'uppercase'
                }
            }
            ],
           
        },
        MuiCheckbox:{
            styleOverrides:{
                root:{
                    color:hometheme.palette.info,
                    '&.Mui-checked':{
                        color:hometheme.palette.info
                    },
                   
                }
            }
        }
    },
    
   
});

// export const Div = styled('div', {
//     name:'MuiDiv',
//     slot:'root'
// })(({hometheme}) => ({
        
//         display:'flex',
//         width: '100%',
//         justifyContent:'center',
//         alignItems:'center',
//         padding:0,
//         border:'3px dashed red',
//         marginTop:'1.5rem'
// }));