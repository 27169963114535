import {  Grid,  ThemeProvider, Typography, CircularProgress,useMediaQuery, Divider} from '@mui/material';
// import {theme} from './About.theme';
import {theme} from '../Home/Home.theme';
import React, { useEffect, useState } from 'react';
import {getAll} from '../../api/api';
import { SectionContent } from '../SectionContent/SectionContent';
import { LoadingDisplay } from '../../Reusables/LoadingDisplay';

const aboutCollection = ['coreBeliefs','mission'];


export function About() {
    const [isLoading, setisLoading] = useState(true);
    const [about,setAbout] = useState([]); 
    const [coreBeliefs,setcoreBeliefs] = useState([]); 

    useEffect(()=>{
        let promise1 = new Promise ((resolve,reject) => getAll(aboutCollection[1]).then(data=>{
            
            setAbout(data.data);
            // setisLoading(false);
            resolve(data.data);
        }).catch(e=>{
            setAbout([]);
            console.error('Error trying to get about data: ',e);
            reject(e);
        }));
        let promise2 =  new Promise ((resolve,reject) => getAll(aboutCollection[0]).then(data=>{
            
            setcoreBeliefs(data.data);
            
            resolve(data.data);
        }).catch(e=>{
            setcoreBeliefs([]);
            console.error('Error trying to get about data: ',e);
            reject(e);
        }));

        Promise.allSettled([promise1,promise2]).then((results)=>{
        setisLoading(false);
            results.forEach((result)=>console.debug(result.status))},
        );
    },[]);
  
    return (
        <ThemeProvider theme={theme}>
            {(isLoading) ? 
            <Grid
                display="flex" flexDirection={"column"} alignItems={"center"} minWidth={"100%"} minHeight={"100%"} justifyContent={"center"}>
                <LoadingDisplay />
            </Grid>
                :
            <Grid  variant="mainContainer">
                <Grid  
                    variant="sectionContainer">
                    <Typography variant="h2" sx={{alignSelf:'center',marginBottom:'5%'}} >Core Beliefs</Typography>
                    <Grid 
                        variant={"sectionContent"}
                        style={{width:'fit-content'}}
                    >
                        {coreBeliefs.map(section => {
                            return(    
                                <Grid>
                                <SectionContent section={section}/>
                                <br></br>
                                <Divider />
                
                                <br></br>
                                </Grid>     
                            );
                        })}
                    </Grid> 
                    <Typography variant="h2" sx={{alignSelf:'center',marginTop:'10%',marginBottom:'5%'}} >Mission And Vision</Typography>
                    <Grid
                        variant="sectionContent"
                        style={{width:'fit-content'}}
                    >
                        {about.map(section => {
                            return(         
                                <Grid>
                                <SectionContent section={section}/>
                               
                              {!( about.indexOf(section) == about.length-1) && <Divider />}
                      
                                <br></br>
                                </Grid>     
                            );
                        })}
                    </Grid>
                        
                </Grid>
            </Grid>
            }
        </ThemeProvider>
    );
}