
//This hook is used when a text change occurs on an object in the admin portal

//Image Change Object
//This object only exists in session storage when a change occurs
/* 
{
  Type: "text", // only option is image
  Action: ["create","modify","delete"], // enum
  Location: {
    Collection: " ", //name of the collection that holds the object
    Index: 0 //Location of text block in the list on the page. controls the order in which it will be displayed.
  },
  Data: {
   id: "" //Object id in firebase storage
  }

}


*/

import { useMutation, useQueryClient } from '@tanstack/react-query';
function addData ({component,action,data}){
  //Create session storage object to be used later when saving changes
  return new Promise((resolve,reject)=>{
    let prev = JSON.parse(sessionStorage.getItem('changes'));
    // return getSessionStorage().then(prev=>{
      //Create Session Text Object
      let dataSess = {
        type: "text",
        action: action,
        location: {
          collection: component,
          index: data.index
        },
        data:data
      }

      //add new changes
      if(prev){
        let current = prev.find(item=> 
          ((item.action == dataSess.action) || (item.action == "create" && dataSess.action == "modify")) 
          && 
          ((item.data.id == dataSess.data.id) && (item.type == dataSess.type))
          );
        if(current){
          let ind = prev.indexOf(current);

          //Keep everything the same, just add the new data to the original change object. so the object will still have a create action
          prev[ind].data = dataSess.data;

          //keep the original action if someone modified a newly created object
          // if(current.action == 'create' && dataSess.action == 'modify'){
          //   prev[ind].action = 'create';
          // }
        }else{
          prev.push(dataSess);
        }
      
      }else{
        prev = [dataSess];
      }
      sessionStorage.setItem('changes',JSON.stringify(prev));
      //TODO:error handling with session storage?
      // addToSessionStorage(prev);
      resolve(dataSess);
    });
  // })
}

export function useModifyData({collection}){
    const queryClient = useQueryClient();
    const {status,error,mutate,mutateAsync}= useMutation({
      mutationFn: addData,
      onSuccess: newData => {
        queryClient.setQueryData([collection],(oldData) => { 
          // if newData exists already then just update that
          //TOOD: trying to make this better, this is the old way
            // let exists = oldData.find(el => el.index === newData.location.index);
            if(collection=='recurrence'){
              // return newData.data;
              queryClient.invalidateQueries([collection,newData.data.id]);
            }else{
              let exists = oldData.find(el => el.id === newData.data.id);
              if(exists){
                oldData[oldData.indexOf(exists)] = newData.data;
                return oldData;
              }else{
                oldData.push(newData.data);
                return oldData;
                
              }
            }
          // return oldData;
        })
      }
    });
    
    return [status,error,mutate,mutateAsync];
}

