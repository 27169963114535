
import { Check } from "@mui/icons-material";
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Fade,
    Grid, Modal,
    ThemeProvider,
    Typography
} from "@mui/material";
import { useState } from 'react';
import { create, modify } from "../../../api/api";
import { theme } from './Prayer.theme';

export function UpdateLikes({likeState}){

    const {handleClose,open,selected,setLikes,matches} = likeState;
    const [isLoading,setisLoading] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const updateLikes = (event) =>{
        let id = selected.current.id;
        let count = selected.current.likes;
        let emailMe = selected.current.emailMe;
        let body = {    
            likes: count+1
        }
        modify('prayer',id,body).then(data=>{
            if(data.error){
                console.error('Error: ',data.error);
            }
            else{
                setLikes(count+1);
                if(emailMe){
                    handleSendingEmail(selected.current);
                }  
            }
        }).catch(err =>{
            console.error('error adding likes ',err);
        });
    }

    
    const handleSendingEmail = (select) =>{
        setisLoading(true);
        let email = {
            to: select.email,
            message: {
              subject: 'Someone has prayed for you!',
              text: 'Someone has prayed for your prayer request: "' + select.request + '"',
              html: 'Someone has prayed for your prayer request: "' + select.request + '"',
            },
        }
       
        create('email',email).then(data=>{
            setTimeout(()=>{
                setisLoading(false);
                setisSuccess(true);
            },"1000");
            if(data.error){
                console.error('Error: ',data.error);
            }
            else{
                setTimeout(() =>{
                    handleClose();
                }, "4000");
            }
        }).catch(err =>{
            console.error('error adding likes ',err);
        });
    }

    return(
        <ThemeProvider theme={theme}>
            <Modal              
                open={open}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                    }}
            >
                <Fade in={open}
                    mountOnEnter
                    // style={{ transformOrigin: '0 0 0' }}
                    {...(open ? { timeout: 500 } : {})}
                >
                    <Box  
                        sx={{
                            width: matches?'75%':"fit-content",
                            boxShadow:8,
                            // boxShadow:'0px 2px 2px 0px rgba(0,0,0,0.23)',
                            padding:'2rem',
                            position: 'absolute',
                            top: '50%',
                            
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            // width: 400,
                            backgroundColor: 'white',
                        }}
                    >
                         {isSuccess?
                            <Alert icon={<Check fontSize="inherit" />} severity="success">
                                We let {selected?.current?.name} know that you prayed for them!
                            </Alert>
                            :
                            <></>
                            }  

                            <Backdrop
                                sx={{ color: '#0000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress />
                            </Backdrop>
                        <Grid 
                        sx={{display:'flex',flexDirection:'column',marginTop:'5%'}}
                        >    
                            <Typography style={{alignSelf:'center',fontWeight:'bold'}} variant="prayer" id="modal-modal-title">
                                Are You Sure You Prayed?
                            </Typography>
                            <Divider />
                            <br/>
                            <Grid  sx={{display:'flex',flexDirection:'column',alignItems:'center'}}
                            >
                                <Typography variant='prayer' id="modal-modal-description">
                                    "{selected?.current?.request? selected.current.request: ""}"
                                </Typography>
                                <Typography variant='name'>
                                    {selected?.current?.name? selected.current.name:""}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display:'flex', marginTop:'5%', justifyContent:'center'}} >
                            <Button disabled={isLoading || isSuccess} onClick={updateLikes}>Yes,I Prayed</Button>
                            <Button disabled={isLoading || isSuccess} onClick={handleClose}>No, Not Yet</Button>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </ThemeProvider>
    )
}