
//This hook is used when an image is deleted from the section in the admin portal

//Image Change Object
//This object only exists in session storage when a change occurs
/* 
{
  Type: "image", // only option is image
  Action: ["create","modify","delete"], // enum
  Location: {
    Collection: " ", //name of the folder/collection that holds the image
    Object_id: 0 //Text block object id (generated id in the db) where this image is located , if empty then probably hero (optional)
  },
  Data: {
    Name: "", //file name of the image
    Url: "", // Url pointing to file in firebase
    Blob: "", // Blob pointing to image in the session
  }

}

Firebase Storage Structure
  Top Level Folder structure is [admin/website]_[development/prod] (where admin is the cms portal and website is the actual live website and the development/prod is the environment)
  Second Level Folder is the page/collection where the image is on the site
  Third Level is the file type (image or document)
  Name of the file will be w/e the name is and then it will append an index in the front to indicate where in the list it is located //TODO: might change this
  ex. admin_development/aboutwho/image/0_ChurchHands.jpeg
*/



//TODO: create a file hook , need to add the same flow as image to add file to db first and then add it to the text object
import  {useMutation, useQueryClient} from '@tanstack/react-query';
import { useSession } from '../utils/useSession';


function removeImage ({selected,action,type,imageData,getSessionStorage,addToSessionStorage}){
 
  return new Promise((resolve,reject)=>{
    
    let prev = JSON.parse(sessionStorage.getItem('changes'));
      let dataSess = {
        type:type,
        action:action,
        location: {
          collection: selected.component,
          object_id: selected.id
        },
        data: imageData
      }
      //add new changes
      if(prev){
        /*
        UPDATE SESSION STORAGE WITH IMAGE CHANGE
        Check to see if there is a change in the session storage for this image already. 
        If so just replace it. 
        If not then add this change to the list  */

        if(type == "image"){
          let current = prev.find((item,index) => 
          (item.location.object_id == selected.id) 
            && 
            (item.type == type)
            &&
            (item.action != "delete")
            &&
            ((item.data.url == imageData.url) || (item.data.blob == imageData.blob))
            );
            if(current){
              let ind = prev.indexOf(current);

              prev[ind].action = "delete";
               resolve(prev);
          }else{
              prev.push(dataSess);
               resolve(prev);
          }
        }else{
          let current2 = prev.find((item,index) => 
          (item.location.object_id == selected.id) 
            && 
            (item.type == type)
            &&
            (item.action != "delete")
            &&
            (item.data.data == imageData.content)
            );

            if(current2){
              let ind = prev.indexOf(current2);

              prev[ind].action = "delete";
              resolve(dataSess);
          }else{
              prev.push(dataSess);
               resolve(prev);
          }
        }

        
        // resolve(dataSess);

      }
      //If session storage does not exist yet (there have been no changes made to the entire admin portal)
      else{
          prev = [dataSess];
           resolve(prev);
      }
      sessionStorage.setItem('changes',JSON.stringify(prev));
      
    });
  // });
}

export function useDeleteFile({collection}){
    const queryClient = useQueryClient();
    // const {addToSessionStorage,getSessionStorage} = useSession();
    const {status,error,mutate,mutateAsync}= useMutation({
      mutationFn: removeImage,
      onSuccess: newData => 
      // {
         queryClient.invalidateQueries([collection])
        //  return newData;
        // queryClient.setQueryData([collection],(oldData) => { 
        //   //if newData exists already then just update that
        //     let exists = oldData.find(el => el.index === newData.location.index);
        //     if(exists){
        //       oldData[exists.index].image = newData.data.image;
        //       return oldData;
        //     }else{
        //       return oldData.push(newData.data);
        //     }
          
        //   // return oldData;
        // })

      // }
    });
    
    return [status,error,mutate,mutateAsync];
}