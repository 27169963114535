import { Divider, Grid, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import {theme} from './About.theme';
import { LoadingDisplay } from '../../Reusables/LoadingDisplay';
import { theme } from '../Home/Home.theme';
import { useLoadData } from '../Hooks/useLoadData';
import { SectionContent } from '../SectionContent/SectionContent';

const collection = 'announcement';


export function Announcement() {
    const [isLoading, setisLoading] = useState(true);
   
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [status,error,collectionList] = useLoadData({collection:collection});

    useEffect(()=>{
        if(status == 'success' && Array.isArray(collectionList)){
            setisLoading(false);
        }
    },[status,collectionList]);

  
    return (
        <ThemeProvider theme={theme}>
            {(isLoading) ? 
            <Grid
                display="flex" flexDirection={"column"} alignItems={"center"} minWidth={"100%"} minHeight={"100%"} justifyContent={"center"}>
                <LoadingDisplay />
            </Grid>
                :
            //Whole page not including border or header. Appears under header      
            <Grid  variant="mainContainer">
                <Grid  
                    variant="sectionContainer">
                    <Grid 
                        variant={"sectionContent"}
                    >
                        
                        {collectionList.length>0?
                            collectionList.map((section,index) => {
                                return(    
                                    <Grid key={index}>
                                        <SectionContent key={index} section={section}/>
                                        <br></br>
                                        {(collectionList.length-1 != index) && <Divider key={index} />}
                                        <br></br>
                                    </Grid>     
                                );
                        }):
                        <Grid 
                        style={{
                            width:'65%',
                           
                            marginTop:'10%',
                            marginBottom:'20%',
                            alignSelf:'center',
                          
                        }}
                        >
                            <SectionContent key={'noNewAnnouncements'} section={{title:'There Are No New Announcements'}} />
                        </Grid>
                        }
                        
                    </Grid>
                    <br></br>   
                </Grid>
            </Grid>
            }
        </ThemeProvider>
    );
}