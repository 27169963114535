import React from 'react';

import { 
    Drawer, 
    Grid,
    Divider,
    Typography,
} from "@mui/material";

function EditDrawerAbout ({title,isOpen,children}){
    return(
        <Drawer open={isOpen} 
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                
                sx: { 
                    '&.MuiPaper-root':{
                        width: "18vw", 
                        padding:'10px'
                    }},
              }}
            anchor="right"
            variant="persistent">
            <Grid 
                
                style={{
                    display:'flex',
                    flexDirection:'column',
                    pointerEvents:'auto',
                    zIndex:500,
                    marginTop:'4rem',
                }}
            >
                <Grid
                    style={{
                        display:'flex',
                        justifyContent:'center',
                        borderBottom:'1px solid black',
                        alignItems:'center',
                        width:'100%',
                    }}
                >
                    <Typography
                        id='edit-label'
                        style={{
                            marginTop:'1rem',
                            fontWeight:'bold',
                        }}
                    >
                        Edit {title}
                    </Typography>
                    <Divider style={{backgroundColor:'black'}} />
                </Grid>
                    {children}
            </Grid>
        </Drawer>
    )
}

export default EditDrawerAbout
