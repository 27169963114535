
import  {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import { getAll } from '../../api/api';
import { deleteItem } from '../utils/CommitChanges';
import { useSession } from '../utils/useSession';



function deleteData ({component,action,data,getSessionStorage,addSessionStorage}){

  return new Promise((resolve,reject)=>{
    let prev = JSON.parse(sessionStorage.getItem('changes'));
    // return getSessionStorage(prev=>{   
   
    //Create Session Text Object
      let dataSess = {
        type: "text",
        action: action,
        location: {
          collection: component,
          index: data.index
        },
        data:data
      }

      if(prev){
        /*Check to see if the item has changes in session storage. 
        If so, remove that change json*/
        let current = prev.filter(item=> 
          ((item.data.id == dataSess.data.id) && (item.type == dataSess.type)))
        current.map(change=>{
          let ind = prev.indexOf(change);
          //remove item from the array
          prev.splice(ind,1);
        });
        
        //Put the delete change in session storage to be used later
        prev.push(dataSess);
      }else{
        prev = [dataSess];
      }
      // addSessionStorage(prev);
      sessionStorage.setItem('changes',JSON.stringify(prev));
      return resolve(data);
    });
  // })
}


export function useDeleteData({collection,props}){
    const queryClient = useQueryClient();
    const{setdeleteResp,setSelected,setOpenDelete} = props;
   
    const {status,error,mutate} = useMutation({
      mutationFn: deleteData,//deleteItem,
      onSuccess: deleteData => {
        queryClient.invalidateQueries([collection]);
        setdeleteResp({severity:"success",message:"Item Successfully Deleted."});
        setSelected(false);
        setOpenDelete(false);
        
        
      },
      onError: deleteData =>{
        queryClient.invalidateQueries([collection]);
        setdeleteResp({severity:"error",message:" Sorry, we were unable to delete that item. Please try again."});
        setSelected(false);
        setOpenDelete(false);
   
      }
    });
    
    return [status,error,mutate];
}