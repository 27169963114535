
import  {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import { getAll, getFile } from '../../api/api';
import { useSession } from '../utils/useSession';

function loadFile({ui,path,filename,key,getSessionStorage}){
 
  let sessionStore = JSON.parse(sessionStorage.getItem('changes'));
  // return getSessionStorage(sessionStore=>{
  // let sessionStore = JSON.parse(sessionStorage.getItem('changes'));
  
    //Check if there is an unsaved version of the file stored in the session storage
    let obj;
    if(sessionStore){
        obj = sessionStore.find(obj => (obj.location.collection.toLowerCase() == key.toLowerCase()) && obj.location.object_id.toLowerCase() == 'hero');
    }
    if(obj){
      return obj.data.blob;
    }else{
      return getFile(ui,path,filename)
      .then(data => {
        if(!data.error){
          // if(data.code.toString().startsWith('20')){
            return data.data.url;
          // }
        }else{
            return "";
        }
      }).catch((e)=>{
        console.error('Error: useLoadFile: ',e);
      });
    }
  // });
}

export function useLoadHomeHeroImage({ui,path,filename,key,getSessionStorage}){
  
  const {status,error, data} = useQuery({
    retry:3,
    // refetchOnMount:'always',
    enabled: (![null,undefined].includes(filename)),
    queryKey: [key],
    queryFn:()=> loadFile({ui:ui,path:path,filename:filename,key:key,getSessionStorage}),
  });
  return [status,error,data];
}