import { Check,Close,Error } from "@mui/icons-material"
import { Alert, Backdrop, IconButton } from "@mui/material"



export const CustomSuccessAlert = ({message,onClose}) =>{
    return(
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        >
            <Alert icon={<Check fontSize="inherit" />} severity="success"   
        //     action={
            
        //         <Close onClick={()=>onClose}/>
 
        // }
        >
            {message}
            </Alert>
        </Backdrop>
    )
}

export const CustomErrorAlert = ({message,onClose}) =>{
    return(
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        >
            <Alert icon={<Error fontSize="inherit" />} severity="error"
        //     action={
        //           <IconButton 
        //           style={{
                  
        //           }}
        //           onClick={()=>onClose}>
                  
        //           <Close /> 
        //       </IconButton>   
        // }
        >
            {message}
            </Alert>
        </Backdrop>
    )
}