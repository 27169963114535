import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue,red,orange } from '@mui/material/colors';
import {theme as hometheme } from '../Home/Home.theme';

export let theme = createTheme({
    components: {
        MuiGrid:{
            variants: [
                {
                    props: {variant:'SectionContentMain'},
                    style:{
                        display:'flex',
                    //  position:'relative',
                        alignSelf:'center',
                        width:'70vw',
                        // marginBottom:'1vh',
                        height:'auto',
                        backgroundColor:'rgba(255,255,255,0.8)',
                        justifyContent:'center',
                        // border:'2px solid orange',
                    }
                },
                {
                    props: { variant: 'SectionContentImageGrid'},
                    style: {
                        display:'flex',
                        flex:1,
                        padding:'1rem',
                        marginRight:'2vw',
                        position: 'relative',
                        width:'100vw',
                        // width: `calc(50% - 105px)`,
                        // margin: '35px',
                        justifyContent:'flex-end',
                        // marginRight:'10vw',
                        // border: `4px dashed ${orange[500]}`,
                    }
                },
                {
                    props:{variant: 'SectionContentTextGrid'},
                    style:{
                        // border: `4px dashed ${red[500]}`,
                        display:"flex",
                        flex:1,
                        flexDirection:"column",
                        justifyContent:'center',
                        // minWidth:"50%"

                    }
                }
            ]
        },
        MuiTypography:{
            styleOverrides :{
                root:({ownerState}) => ({
                    fontFamily: 'League Spartan',
              }),
              h2:{
                fontSize:'44px',
                color: hometheme.palette.primary.main,
                fontWeight: 'bold'
              },
              h3:{
                fontSize:'24px',
                color: 'black',
                fontWeight: 'bold'
              },
              h4:{
                fontSize:'20px',
            
              },
              body1:{
                fontSize:'18px',
                textTransform: 'none',
                // fontSize: '1,2rem',
              },
              body2:{
                fontSize:'16px',
              },
              button:{
                textTransform: 'uppercase',
                fontSize: '1rem',//'1.3rem',//theme.fontSize.medium,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontFamily:'League Spartan'
              }, 
            },
           
            variants:[
                {
                    props: {variant: 'sectiontitle'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '2.1rem',
                        color: hometheme.palette.primary.main
                        
                    }
                },
                {
                    props: {variant: 'subtitle'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '1.8rem',
                        color: hometheme.palette.secondary.main
                    }
                },
                {
                    props: {variant: 'body'},
                    style:{
                        fontWeight: 'normal',
                        fontSize: '1.5rem',
                        color: 'black'
                        
                    }
                },
                
            ],
            

        },
      
    }
    
  });
theme = responsiveFontSizes(theme);
