import { Clear, DriveFolderUpload } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";



export function UploadImage ({
    drawerWidth,
    handleClickedRemoveImage,
    selected,
    file,
    handleClickIcon,
    hiddenFileInput,
    handleFileUpload
}){ 

    return(
        <Grid       
        >
            <Grid 
                style={{
                    position:'relative', 
                    left:10,
                    display:'flex',
                    justifyContent:'center',
                    marginTop: '3rem',
                    // zIndex:1000,
                    backgroundColor:'black',
                    height:'176px',
                    // border:'2px solid blue',
                    width:`calc(${drawerWidth}px - 20px)`,
                    // alignSelf:'center'
                }}
            >
                <IconButton 
                    style={{
                        zIndex:1200,
                        position:'absolute',
                        right:'0',
                    }}
                    aria-label="delete"
                    onClick={(e)=>handleClickedRemoveImage(e,selected)}
                    >
                    <Clear style={{color:'white', }}
                    />
                </IconButton>
                <img
                    style={{
                        opacity:'0.7',
                        //For Object fit to work the image needs a width and height
                        width:'100%',
                        height:'100%',
                        objectFit:'cover',
                    }}
                    src={file}
                
                >
                </img>
                <IconButton
              
                    style={{
                        position:'absolute',
                        zIndex:1200,
                        marginTop: '3.5rem',
                    }}
                    onClick={handleClickIcon}
                    >
                    <DriveFolderUpload
                        style={{
                            // position:'absolute',
                            zIndex:2000,
                            color:'white'
                        }}
                        fontSize={'large'}
                    > 
                    </DriveFolderUpload>
                
                </IconButton>
                
            </Grid>
        
            <input 
                // class="filepond"
                // className='filepond'
                // name="filepond" 
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileUpload}
                // onupdatefiles={(fileitem=>handleFilePond(fileitem))}
                hidden
            />
            
        </Grid>
    )
}