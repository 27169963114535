import { Settings } from "@mui/icons-material";
import { Badge, Grid, IconButton, Link, ThemeProvider, Toolbar, Tooltip, createTheme, } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEdit } from "../Hooks/useEdit";
import { useAuth } from "../auth/useAuth";
import { SideDrawer } from "./Drawer";

const TopBar = ({pathname,toolbarHeight,handleReset,setOpenPublish,handleLogout,handleRevert}) =>{


    const theme = createTheme({
        palette: {
            primary: {
              // light: '#757ce8',
              main: '#661414',
              // dark: '#002884',
              contrastText: '#fff',
            },
            secondary: {
                // light: '#757ce8',
                main: '#462234',
                // dark: '#002884',
                contrastText: '#fff',
              },
              info:{
                // light: '#757ce8',
                main: '#E5BD21',
                // dark: '#002884',
                contrastText: '#462234',
              }
        },
        components: {
          // Name of the component
          MuiLink:{
            defaultProps: {
          
                underline:'hover',
                
              },
            styleOverrides: {
                // Name of the slot
                root: {
                    fontFamily:"League Spartan",
                    color: 'black',
                    marginLeft:20,
            
                    '&.MuiLink-button ':{
                      '&:disabled':{
                        color:'grey',
                        pointerEvents:'none'
                       
                      }
                    }
                }
                
            },
          },
          MuiTooltip: {
                styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontFamily:'League Spartan',
                    
                
                },
                },
            },
            MuiTypography:{
                styleOverrides:{
                root:{
                    fontFamily: 'League Spartan',
                    textTransform: "uppercase",
                    
                },
                }
            },
           
        },
      });
    
    const {
        enablePublish,
        enableSave,
        enableReset,
        enableRevert
    } = useEdit();


    let session = JSON.parse(sessionStorage.getItem('changes'));
    useEffect(()=>{
        session = JSON.parse(sessionStorage.getItem('changes'));
    });

    const {isAdmin} = useAuth();
    // const session = JSON.parse(sessionStorage.getItem('changes'));
    const navigate = useNavigate();
    return(
        <ThemeProvider theme={theme}>
            <Toolbar 
                style={{
                    display:'flex',
                    height: `${toolbarHeight}px`,
                    width: '100%', //`calc(100% - ${drawerWidth}px)`,
                    position:'fixed',
                    justifyContent:'space-between',
                    backgroundColor:'white',
                    zIndex:'10000',
                    boxShadow:'2px 2px 10px grey',
                }}
            >
                <SideDrawer pathname={pathname} />
                <Grid
                >
                    {/* <Tooltip  enterDelay={300} leaveDelay={200}  describeChild title="Reset this page back to published version">
                        <Link component="button" disabled={!enableReset} onClick={(e)=>handleReset(e)}>Reset</Link>
                    </Tooltip> */}
                    <Tooltip  enterDelay={300} leaveDelay={200}  describeChild title="Undo all unsaved changes on every page">
                        <Link component="button" disabled={!enableRevert} onClick={(e)=>handleRevert(e)}>Revert</Link>
                    </Tooltip>
                    <Tooltip  enterDelay={300} leaveDelay={200}  describeChild title="Save all changes">
                        <Badge invisible={!session} color="primary" variant="dot">
                            <Link component="button" type="submit" form="textEditForm" 
                            disabled={!session} 
                            >Save</Link>
                        </Badge>
                    </Tooltip>
                    <Tooltip  enterDelay={300} leaveDelay={200}  describeChild title="Publish all changes to live site">
                        <Link  component="button" disabled={!isAdmin} onClick={()=>setOpenPublish(true)}>Publish</Link>
                    </Tooltip>
                    <Tooltip  enterDelay={300} leaveDelay={200}  describeChild title="Go to live site">
                        <Link  component="button" onClick={()=>window.open("/", "_blank")}>Live Site</Link>
                    </Tooltip>
                    <Link  component="button" onClick={handleLogout}>Logout</Link>
                    {isAdmin&&<IconButton onClick={()=>navigate("/admin/users")}>
                        <Settings />
                    </IconButton>}
                </Grid>
            </Toolbar>
        </ThemeProvider>
    )
}

export default TopBar;