// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import { getStorage, ref } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import uploadDefault from "../../assets/upload_image_placeholder.png";
import { useEdit } from '../Hooks/useEdit';
import { useSection } from "../Hooks/useSection";
import { UploadImage } from './UploadImage';

const storage = getStorage();

export function ImageEdit ({
    img,
    handlePreview,
    drawerWidth,
    handleDelete,
}){ 
    const {
        selected,
        selectedItem,
        setSelected,
        handleValidation
        } = useEdit();
    
    const [file, setFile] = useState(uploadDefault);
    const [func,status] = useSection({collection:selected.component});
    
    useEffect(()=>{
        img = ((img == "")|| (img==undefined))?uploadDefault:img;
        setFile(img);
    },[img])
    const hiddenFileInput = useRef(null);

    const handleClickIcon = event => {
        event.preventDefault();
        hiddenFileInput.current.click();   
    };

    const handleClickedRemoveImage = (e,selected) =>{
        let key = ""
        if(selected.component.toLowerCase() == 'hero' ){
            key = selected.page+selected.component+selected.index;
        }else{
            key = selected.page+selected.index;

        }
        key = key.replace('/',"")

        var storageReference = ref(storage,selectedItem.image);
        let imgName = storageReference.name;
        let data = {
            name:imgName,
            blob:  file.startsWith("data:")? file:"",
            url: file.startsWith("https://")? file: ""
        }
        handleDelete(data);
    }
   
    const handleFileUpload = (event) =>{
        let maxFileSize = 1000000;
        console.debug('handle file ',event)
        // event.preventDefault();
        const reader = new FileReader();
       
        let file = event.target.files[0];
        if (file.size > maxFileSize) { // 5 MB in bytes
            alert('File size exceeds ' + maxFileSize/(1000*1000) + ' MB. Please choose a smaller file.');
            event.target.value = ''; // clear the file input
        } else{
            reader.onload = function(file) { 
                var fileName = file.name;
                return function(e){
                    var newfile = e.target.result;
                    // do something with the URL in the DOM,
                    // then save it to local storage
                    let key = ""
                    if(selected.component.toLowerCase() == 'hero' ){
                        key = selected.page+selected.component+selected.index;
                    }else{
                        key = selected.page+selected.index;

                    }
                    key = key.replace('/',"")
                    setFile(newfile);
                    let data = {
                            name: `${selected.page.split("/").join('')}_${selectedItem.id}_${fileName}`,//key+'.jpeg',
                            url:"",
                            blob:newfile
                        
                    }
                    event.target.value = '';
                    handlePreview(data);
                }
            }(file);  
            reader.readAsDataURL(file);
        }
    }

    return(
       <UploadImage 
            drawerWidth={drawerWidth}
            handleClickedRemoveImage={handleClickedRemoveImage}
            selected={selected}
            file={file}
            handleClickIcon={handleClickIcon}
            hiddenFileInput={hiddenFileInput}
            handleFileUpload={handleFileUpload}
       />
    );

}