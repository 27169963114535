import {Grid, ThemeProvider, Typography,Link, Divider,useMediaQuery, List, ListItem, ListItemButton, ListItemText, Collapse, ListItemIcon } from '@mui/material';
import { YouTube, Facebook, StarBorder } from '@mui/icons-material';
import { Fragment, useEffect, useState } from 'react';
import {theme} from './Foother.theme';
import crest from '../../assets/WOG Crest_nofill.png';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const handleClick = (event) => {
        
        setOpen(!open);
    };
    const handleClick2 = (event) => {
        
        setOpen2(!open2);
    };
    const handleClick3 = (event) => {
        
        setOpen3(!open3);
    };

    
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        // Update the year when the component mounts
        setYear(new Date().getFullYear());
    }, []);

    return (
        <ThemeProvider theme={theme}>

        <Grid variant="main"
            >
            <Grid variant="containerA" >  
                <Grid variant='crestgrid'
                    >
                        <img src={crest} style={matches? {maxWidth:'20vw'}:{maxWidth:'5vw'}}></img>

                </Grid>
                <Grid variant='quote'>
                    <Grid  variant='quoteRowLeft'>
                        <Typography noWrap variant="normal" color="text" size="medium">Instructed By </Typography>
                        <Typography noWrap variant="normal" color="text" size="medium">Led By</Typography>
                        <Typography noWrap variant="normal" color="text" size="medium">Changed By</Typography>
                    </Grid>
                    <Grid variant='quoteRowRight'>
                        <Typography noWrap variant="bold" color="text" size="large">The Word of God</Typography>
                        <Typography noWrap variant="bold" color="text" size="large">The Spirit of God</Typography>
                        <Typography noWrap variant="bold" color="text" size="large">The Love of God</Typography>
                    </Grid>
               
                </Grid>

            </Grid>
            
            {matches?  
            <Grid variant={"smallcontainerB"} >
            <List 
            style={{ width:'100%'}}
            >
                <ListItemButton onClick={handleClick}>
                    <ListItemText primary="Quick Links" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider></Divider>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItemButton
                    component="a" href="/connect/prayer"
                    sx={{ pl: 4 }}>
                        <ListItemText primary="Request Prayer" />
                    </ListItemButton>
                    <ListItemButton 
                    component="a" href="/event"
                    sx={{ pl: 4 }}>
                        <ListItemText primary="Events" />
                    </ListItemButton>
                    <ListItemButton 
                    component="a" href="/connect"
                    sx={{ pl: 4 }}>
                        <ListItemText primary="Connect" />
                    </ListItemButton>
                    <ListItemButton 
                    component="a" href="https://www.youtube.com/@whiteoakgrovembc8556/streams"
                    sx={{ pl: 4 }}>
                        <ListItemText primary="Sermons" />
                    </ListItemButton>
                    <ListItemButton 
                    component="a" href="https://giv.li/fw42tz"
                    sx={{ pl: 4 }}>
                        <ListItemText primary="Give" />
                    </ListItemButton>
                    </List>
                </Collapse> 
            </List>
            <List 
            style={{ width:'100%'}}
            >
                <ListItemButton onClick={handleClick2}>
                    <ListItemText primary="Visit Us" />
                    {open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider></Divider>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                        <Typography variant="bold" color="title" size="medium">Service Time: <Typography variant="bold" color="title" size="small">Sunday, 10AM EST</Typography>
                        </Typography>
                        </ListItem>
                        <ListItem>
                        <Typography variant="bold" color="title" size="medium">Physical Address:         </Typography>
                        </ListItem>
                        <ListItem>
                        <Typography variant="bold" color="title" size="small">2600 Assembly Road, </Typography> 
                        <Typography variant="bold" color="title" size="small">Greensboro, NC 27405</Typography> 
                        </ListItem>
                    </List>
                </Collapse> 
            </List>
            <List 
            style={{ width:'100%'}}
            >
                <ListItemButton onClick={handleClick3}>
                    <ListItemText primary="Contact Us" />
                    {open3 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider></Divider>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                    
                    <List component="div" disablePadding>
                        <ListItem>
                        <Typography variant="bold" color="title" size="medium">Mailing Address</Typography>
                        </ListItem>
                        <ListItem><Typography variant="bold" color="title" size="small">Greensboro, NC 27402, </Typography>
                        <Typography variant="bold" color="title" size="small">P.O. Box 1078</Typography></ListItem>
                    <ListItem>
                    <Typography variant="bold" color="title" size="medium">Phone: <Typography variant='normal' color="title" size="small">(336) 621-3815</Typography></Typography>
                
                    </ListItem>
                    <ListItem>
                    <Typography variant="bold" color="title" size="medium">Email: <Typography variant='normal' color="title" size="small">info@whitoakgrove.net</Typography></Typography>
                    
                    </ListItem>
                    
                    </List>
                </Collapse> 
            </List>
            <Grid  variant='smallsocial'>
                    <YouTube onClick={()=>window.open("https://www.youtube.com/@whiteoakgrovembc8556/featured")}/>
                    <Facebook onClick={()=>window.open('https://www.facebook.com/WOGMBCLive')}></Facebook>
                </Grid>
            </Grid>
            :
            <Grid variant={"containerB"} >
            <Grid variant="section" color="transparent">
                <Typography variant="bolder" color="title" size="medium">Quick Links</Typography>
                <Divider></Divider>
                <Grid variant="sectionList">
                    <Typography component={Link} href="/connect/prayer" underline='none' variant="normal" color="title" size="small">Request Prayer</Typography>
                    <Typography component={Link} href="/event" underline='none' variant="normal" color="title" size="small">Events</Typography>
                    <Typography component={Link} href="/connect" underline='none' variant="normal" color="title" size="small">Connect</Typography>
                    <Typography component={Link} href="https://www.youtube.com/@whiteoakgrovembc8556/streams" underline='none' variant="normal" color="title" size="small">Sermons</Typography>
                    <Typography component={Link} href="https://giv.li/fw42tz" underline='none' variant="normal" color="title" size="small">Give</Typography>
                </Grid>
            </Grid>
            <Grid variant="section" color="transparent">
                <Typography variant="bolder" color="title" size="medium">Visit Us</Typography>
                <Divider></Divider>
                <Grid variant="sectionList">
                    <Typography variant="bold" color="title" size="medium">Service Time</Typography>
                    <Typography variant="bold" color="title" size="small">Sunday, 10AM EST</Typography>
                    <br/>
                    <Typography variant="bold" color="title" size="medium">Physical Address</Typography>
                    <Typography variant="bold" color="title" size="small">2600 Assembly Road</Typography>
                    <Typography variant="bold" color="title" size="small">Greensboro, NC 27405</Typography> 
                    <br/>
                    <Typography component={Link} href="/connect/visit" underline='none' variant="normal" color="title" size="small">Plan Your Visit</Typography>
                </Grid>
            </Grid>
            <Grid variant="section" color="transparent">
                <Typography variant="bolder" color="title" size="medium">Contact Us</Typography>
                <Divider></Divider>
                <Grid variant="sectionList">               
                    <Typography variant="bold" color="title" size="medium">Mailing Address</Typography>
                    <Typography variant="bold" color="title" size="small">P.O. Box 1078</Typography>
                    <Typography variant="bold" color="title" size="small">Greensboro, NC 27402</Typography>
                    <br/>
                    <Grid>
                    <Typography variant="bold" color="title" size="medium">Phone: </Typography>
                    <Typography variant='normal' color="title" size="small">(336) 621-3815</Typography>
                    </Grid>
                    <Grid>
                        <Typography variant="bold" color="title" size="medium">Email: </Typography>
                        <Typography variant='normal' color="title" size="small">info@whitoakgrove.net</Typography>
                    </Grid>
                </Grid>
            </Grid>
            
            </Grid>
            }
            
        </Grid>
        <Grid variant="bottomBar">
            <Grid style={{
                display:'flex',
                position:matches?"relative":'absolute',
                left:matches?0:30,
                // border:'2px solid red'
                }}>
                <div className="pt-lg-10 pt-5 footer bg-white">
                <p style={{color:'white',fontSize:'.8rem'}}>{`© ${year} White Oak Grove. All Rights Reserved.`}</p>
                </div>
            </Grid>
            <Grid  variant='social'>
                <YouTube onClick={()=>window.open("https://www.youtube.com/@whiteoakgrovembc8556/featured")}/>
                <Facebook onClick={()=>window.open('https://www.facebook.com/WOGMBCLive')}></Facebook>
            </Grid>
        </Grid>
      </ThemeProvider>
    );
}