import { Button, Container, Grid,  ThemeProvider, Typography, CircularProgress,useMediaQuery, Collapse, Fade} from '@mui/material';
import {theme} from './Hero.theme';
import {theme as hometheme} from '../Home/Home.theme';

import React, { useEffect, useState } from 'react';
import { blue,green,orange,purple,red, yellow } from '@mui/material/colors';
import {getAll, getFile, getOne} from '../../api/api';


export function Hero(state) {
    const {loaded,hero} = state;
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    
    return (
    <ThemeProvider theme={theme}>
        {/* Whole page not including border or header. Appears under header       */}
            {/* Hero */}
           
            <Grid 
                variant={matches?"smallheroGrid":"heroGrid"}
            >
                 <Collapse in={loaded} 
                 
                 style={{ 
                    position:'relative',
                    // height:'100%',
                    width:'100%',
                    transformOrigin: '0 0 0' }}
                 easing={{
                    enter: "cubic-bezier(0, 1.5, .8, 1)",
                    exit: "linear"
                  }}
                 {...(loaded ? { timeout: 1500 } : {})}
                 >
                    <img 
                    style={
                        matches?
                        {
                            // border: `4px dashed ${purple}`,
                            width:"100%",
                            height:"100%",
                            opacity:".2",
                            objectFit:'cover'
                        }
                        :
                        {
                            // border: `4px dashed ${purple}`,
                            width:"100%",
                            height:"100%",
                            opacity:".2",
                            objectFit:'cover'
                        }} 
                        src={hero.image}>

                    </img>
                </Collapse>
                <Fade
                in={loaded} 
                // style={{ transformOrigin: '0 0 0' }}
                {...(loaded ? { timeout: 2000 } : {})}
                >
                    <Grid 
                        variant='titleGrid'
                    >
                        <Typography variant='h2'>
                            {hero.title}
                        </Typography>
                        
                    </Grid>
                </Fade>
            </Grid>
        </ThemeProvider>

  );
}
  
                
