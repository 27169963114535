import { theme } from "../components/Home/Home.theme"

export const ColorBlockedImage = ({srcImage}) =>{

    return(
        <img style={{
            verticalAlign: 'middle',
            maxWidth:'80%',
            overflowClipMargin: 'content-box',
            overflow: 'clip',

            // display:"flex" ,flex:1, 
            maxHeight:'50vh',
            //  objectFit: 'cover',
            // maxWidth:'75%',
            border:`0px solid ${theme.palette.primary.main}`,boxShadow:`-20px -20px 0px 0px ${theme.palette.primary.main}`}} src={srcImage}>
        </img>
    )
}