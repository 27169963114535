
//This hook is used when an image is changed in the admin portal

//Image Change Object
//This object only exists in session storage when a change occurs
/* 
{
  Type: "file", // only option is image
  Action: ["create","modify","delete"], // enum
  Location: {
    Collection: " ", //name of the folder/collection that holds the image
    Object_id: 0 //Text block object id (generated id in the db) where this image is located , if empty then probably hero (optional)
  },
  Data: {
    Name: "", //file name of the image
    Url: "", // Url pointing to file in firebase
    Blob: "", // Blob pointing to image in the session
  }

}

Firebase Storage Structure
  Top Level Folder structure is [admin/website]_[development/prod] (where admin is the cms portal and website is the actual live website and the development/prod is the environment)
  Second Level Folder is the page/collection where the image is on the site
  Third Level is the file type (image or file)
  Name of the file will be w/e the name is and then it will append an index in the front to indicate where in the list it is located //TODO: might change this
  ex. admin_development/aboutwho/file/0_ChurchHands.jpeg
*/



import  {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import { getAll } from '../../api/api';
import { deleteImage } from '../utils/CommitChanges';
import { useSession } from '../utils/useSession';
function addHero ({selected,action,imageData,addToSessionStorage,getSessionStorage}){
 
  return new Promise((resolve,reject)=>{
    let prev = JSON.parse(sessionStorage.getItem('changes'));
    // return getSessionStorage(prev=>{ 
      let dataSess = {
        type:'image',
        action:action,
        location: {
          collection: selected.component,
          object_id: selected.id
        },
        data: imageData
      }

      //add new changes
      if(prev){


        /*
        UPDATE SESSION STORAGE WITH IMAGE CHANGE
        Check to see if there is a change in the session storage for this image already. 
        If so just replace it. 
        If not then add this change to the list  */
          let current = prev.find(item => 
            ((item.action == dataSess.action) 
            && 
            (item.location.object_id == dataSess.location.object_id) 
            && 
            (item.type == dataSess.type))
            );
          if(current){
              let ind = prev.indexOf(current);
              prev[ind] = dataSess;
              // prev[ind]["imageData"] = data;
          }else{
            // dataSess["imageData"] = data
              prev.push(dataSess);
          }
           resolve(dataSess);
        
      }else{
          prev = [dataSess];
           resolve(dataSess);

      }
      // addToSessionStorage(prev);
      sessionStorage.setItem('changes',JSON.stringify(prev));
    // resolve(dataSess);
   });
  // })
}


export function useModifyHomeHeroImage({collection}){
  const queryClient = useQueryClient();
  
  const {status,error,mutate}= useMutation({
    mutationFn: addHero,
    onSuccess: newData => {
      queryClient.setQueryData([collection],(oldData) => { 
        return newData.data.blob;
      })
    }
  });
  
  return [status,error,mutate];
}